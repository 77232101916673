import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

export default function Callback({ location }) {
  useEffect(() => {
    let search = location.hash;
    let params = new URLSearchParams(search);
    let idp_token = params.get("id_token");
    localStorage.setItem("access_token", params.get("#access_token"));
    localStorage.setItem("idp_token", idp_token);
    let currentDate = new Date();
    let milliseconds = +params.get("expires_in") * 1000;
    localStorage.setItem(
      "expiry_time",
      new Date(currentDate.getTime() + milliseconds).toString()
    );
  }, [])

  return (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
}

// import React from 'react'
// import { useSignIn, useSignOut } from "react-auth-jwt"
// import { useHistory } from 'react-router-dom';
// import { Cookies } from 'react-cookie';
// const Callback = (props) => {

// const history = useHistory();
// const signIn = useSignIn();
// const cookies = new Cookies();
// const domainName=window.location.hostname;
// const componentDidMount = (props) => {
// let search = props.location.hash;
// let params = new URLSearchParams(search);
// signIn(params.get("#access_token"), params.get("expires_in"), {});
// localStorage.setItem("idp_token",params.get("id_token"));
// cookies.set('idp_token', params.get("id_token"), { path: '/', domain: domainName, secure: true, sameSite: 'None' });
// history.push('/');
// }
// componentDidMount(props);
// return (
// <div>
// Redricting...
// </div>
// )
// }
// export default Callback;
