import axios from "axios";
import { CMAT_TOKEN_URL } from "../environment/environment.prod";

class TokenService {
   
    getLocalAccessToken() {
      const token = JSON.parse(localStorage.getItem("token"));
      return token;
    }


    updateLocalAccessToken() {
        let token = localStorage.getItem('idp_token');
        let expiry_time = localStorage.getItem('expiry_time');
        let currentDateTime = new Date();
        if (currentDateTime.getTime() < new Date(expiry_time).getTime()){
            if(token){
                return axios.get(CMAT_TOKEN_URL, {
                    headers:{
                        'X-CognitoToken': token,
                    }})
            }
        } else {
            localStorage.clear();
            window.location.href = '/login'
        }
        return null;
    }
  }
  export default new TokenService();

  // const params = new URLSearchParams();
        // params.append("grant_type", "client_credentials")
        // return publicRequest 
        // .post(tokenUrl, params, {
        //     headers:{
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         'Authorization': `Basic ${base64Client}`
        //     }
        // }) 
