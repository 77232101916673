import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

import Home from "./pages/Home/Home";
import "@progress/kendo-theme-default/dist/all.css";
import GcmToCommodityCodeMapping from "./pages/gcm-commodity-code-mapping/GcmToCommodityCodeMapping";
import QuaterlyCostSubmisson from "./pages/QuarterlyCostSubmission/QuarterlyCostSubmission";
import CommodityCodeScreen from "./pages/commodityCodeScreen/CommodityCodeScreen";
import ShouldBeCost from "./pages/shouldBeCost/shouldBeCost";
import TopDownDiscount from "./pages/TopDownDiscount/TopDownDiscount";
import CostAllocation from "./pages/CostAllocation/costAllocation"
import CostSubmission from "./pages/CostSubmissionView/costSubmission";
import { getRequest} from './services/api.request';
import PartNumTOGrp from "./pages/partNumToGrp/partnumToGrp";

import SideNavList  from './layout/sideNavList';
import "./App.css";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import Callback from "./auth/Callback";
import { Switch } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import OneTimeCharge from "./pages/OneTimeCharge/OneTimeCharge";
import ReductionInitative from './pages/ReductionInitative/ReductionInitative';
import HeirarchyGroupParts from "./pages/HeirarchyGroupParts/HeirarchyGroupParts";

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState({});

 

  useEffect(()=>{
    let userId = "";
    let idp_token = localStorage.getItem("idp_token");
    if(idp_token){
      let { email } = jwt_decode(idp_token);
      userId = email?.toLowerCase();
    }
    let path = `/cmat_user/${userId}`;

    const handleResponse = (response) => {
      let { items } = response.data;
      if(items && items.length > 0){
        setUser({...items[0], user_id: userId})
      }
    }
    getRequest(path, handleResponse);

  },[])
  return (
    <React.Fragment>
      <UserContext.Provider value={user}>
        {
            Object.keys(user).length === 0 ? <ProgressBar /> : ""
        }
        <BrowserRouter>
          <Switch>
            <Route exact path="/saml/callback" component={Callback}/>
            <SideNavList>
              <Route exact={true} path="/" component={Home} />
              <PrivateRoute path="/GCM" component={GcmToCommodityCodeMapping}></PrivateRoute> 
              <PrivateRoute path="/Commodity" component={CommodityCodeScreen}></PrivateRoute> 
              <PrivateRoute path="/ShouldBeCost" component={ShouldBeCost}></PrivateRoute> 
              <PrivateRoute path="/TopDownDiscount" component={TopDownDiscount}></PrivateRoute> 
              <PrivateRoute path="/QuarterlySubmission" component={QuaterlyCostSubmisson}></PrivateRoute> 
              <PrivateRoute path="/CostSubmission" component={CostSubmission}></PrivateRoute> 
              <PrivateRoute path="/PartNumToGrp" component={PartNumTOGrp}></PrivateRoute> 
              <PrivateRoute path="/CostAllocation" component={CostAllocation}></PrivateRoute> 
              <PrivateRoute path="/OneTimeCharge" component={OneTimeCharge}></PrivateRoute>
              <PrivateRoute path="/ROCOGSReduction" component={ReductionInitative}></PrivateRoute>
              <PrivateRoute path="/hierarchies" component={HeirarchyGroupParts}></PrivateRoute>
            </SideNavList>
          </Switch>
        </BrowserRouter>
      </UserContext.Provider>
    </React.Fragment>
  );
}
export default App;
